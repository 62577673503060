html,
body,
#root,
.eyeson-embedded-application {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Hubot Sans', 'Arial', sans-serif;
  font-size: 100%;
  line-height: 1.3em;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  /* hack for safari keyboard overscroll issue */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; }
  body .eyeson-room {
    height: 100%; }

main {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center; }

a {
  color: #003bcc;
  text-decoration: none; }
  a:not(.MuiButton-root):hover {
    text-decoration: underline; }
  a:not(.MuiButton-root):focus {
    text-decoration: underline; }

.MuiDialog-paper:focus {
  outline: none; }

.a11y-chat-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0.001;
  pointer-events: none; }

.eyeson-mobile-room {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-flow: column; }
  .eyeson-mobile-room > .content {
    display: flex;
    position: relative;
    justify-content: center;
    flex-flow: row;
    width: 100%;
    height: auto;
    overflow: auto;
    flex-grow: 1; }
    .eyeson-mobile-room > .content .eyeson-chat {
      position: absolute;
      inset: 0;
      z-index: 100;
      display: flex;
      flex-direction: column;
      background: rgba(250, 250, 250, 0.97);
      text-align: start; }
      body[data-theme="dark"] .eyeson-mobile-room > .content .eyeson-chat {
        background: rgba(25, 25, 25, 0.97); }
  .eyeson-mobile-room .video-stream-container,
  .eyeson-mobile-room .playback {
    height: auto;
    max-height: 100%;
    overflow: hidden; }
  .eyeson-mobile-room .playback {
    position: absolute;
    top: 0; }
  .eyeson-mobile-room .self-view-frame {
    display: flex;
    justify-content: flex-end; }
  .eyeson-mobile-room .bottom {
    position: absolute;
    width: 100%;
    bottom: 0; }
  .eyeson-mobile-room .self-view {
    position: relative;
    margin: 0;
    right: 0.5rem;
    bottom: 0.5rem; }
    .eyeson-mobile-room .self-view video {
      width: 30vw;
      height: auto; }
    .eyeson-mobile-room .self-view .audio-stream.local {
      padding: 1rem 3rem;
      border: none; }
    .eyeson-mobile-room .self-view .audio-stream.local .avatar {
      padding: 0; }
      .eyeson-mobile-room .self-view .audio-stream.local .avatar .eyeson-avatar {
        height: 3.5rem;
        width: 3.5rem; }
      .eyeson-mobile-room .self-view .audio-stream.local .avatar .sound-wave {
        width: 25px;
        height: 25px; }
  .eyeson-mobile-room .podium-outline {
    height: auto;
    max-height: auto;
    overflow: auto; }
  .eyeson-mobile-room .MuiBottomNavigation-root {
    flex-shrink: 0; }
    .eyeson-mobile-room .MuiBottomNavigation-root .Mui-disabled {
      opacity: .3; }
  .eyeson-mobile-room .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
    padding-top: 8px; }
  .eyeson-mobile-room .MuiIconButton-root {
    padding: 16px 12px; }
  .eyeson-mobile-room .toolbarOptions {
    white-space: nowrap;
    overflow: auto; }

@media screen and (orientation: landscape) {
  .eyeson-mobile-room {
    flex-flow: row; }
    .eyeson-mobile-room .MuiAppBar-root {
      width: 64px;
      padding: 16px 0; }
    .eyeson-mobile-room .MuiBottomNavigation-root {
      width: 80px; }
    .eyeson-mobile-room .MuiToolbar-root,
    .eyeson-mobile-room .MuiBottomNavigation-root {
      height: auto;
      flex-flow: column;
      height: 100%; }
    .eyeson-mobile-room .self-view {
      margin: 0; }
      .eyeson-mobile-room .self-view video {
        width: 20vw; }
    .eyeson-mobile-room .toolbarOptions {
      white-space: normal; } }

.eyeson-container.toolbar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  order: -1;
  width: 4.5rem;
  min-width: 4.5rem;
  height: 100%; }
  .eyeson-container.toolbar hr.separator {
    margin: 2vh 1.4rem;
    border: none;
    border-top: 1px solid gray; }
  .eyeson-container.toolbar .logo {
    display: block;
    max-width: 100%;
    margin: 1.5rem auto 0.5rem; }
    .eyeson-container.toolbar .logo.eyeson-logo {
      width: 2rem; }
  .eyeson-container.toolbar .remaining-time {
    position: absolute;
    background: #003bcc;
    left: 4.5rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: #ffffff;
    border-radius: 0 0.1rem 0.1rem 0;
    font-weight: bold;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); }
  .eyeson-container.toolbar .bottom-controls {
    display: flex;
    flex-flow: column; }

.bottom-toolbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #121212; }
  .bottom-toolbar .bottom-controls {
    display: flex;
    margin: auto; }
    .bottom-toolbar .bottom-controls .eyeson-action-button:hover {
      transition: none;
      background: inherit; }
    .bottom-toolbar .bottom-controls .eyeson-action-button:focus {
      background-color: inherit;
      outline: none; }
    .bottom-toolbar .bottom-controls svg {
      height: 2.3rem; }

.eyeson-collapsible #expand-btn {
  width: 4.5rem;
  padding: 0.4rem 0 0.4rem 0; }
  .eyeson-collapsible #expand-btn:hover {
    box-shadow: none; }

.eyeson-collapsible.expanded {
  display: flex;
  width: 22.1vw;
  min-width: calc(19rem + 4.5rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  position: relative; }
  .eyeson-collapsible.expanded svg.icon {
    width: 2.5rem; }
  .eyeson-collapsible.expanded .tooltip {
    opacity: 1;
    left: -2.9rem;
    top: -2.4rem;
    text-transform: none; }
  .eyeson-collapsible.expanded #expand-btn {
    margin: 0; }

@keyframes pulse {
  0% {
    color: #c6c6c6; }
  50% {
    color: #606060; }
  100% {
    color: #c6c6c6; } }

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(5px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    filter: blur(0px); } }

@keyframes fadeInAndOut {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes pulse2 {
  0% {
    opacity: 0.1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }

@keyframes pop {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

@keyframes fadeIn {
  0% {
    transform: scale(0.8);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 0.5; } }

.pulse {
  text-align: center;
  animation: pulse2 5s infinite; }

.animation-rotate {
  transition: transform 0.2s ease-out; }
  .animation-rotate--180 {
    transform: rotate(180deg); }

.eyeson-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%; }

.eyeson-preview {
  height: 100%;
  overflow: auto; }

.eyeson-preview main {
  height: 100%; }

.eyeson-preview .center {
  display: flex;
  width: 30rem;
  height: fit-content;
  max-width: 30rem;
  position: relative;
  margin: 0; }

.eyeson-preview .content {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 0; }

.eyeson-preview #preview {
  display: flex;
  flex-direction: column;
  z-index: 1; }

.eyeson-preview #preview .preview-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }
  .eyeson-preview #preview .preview-wrapper h4 {
    margin-bottom: 0.6rem; }

.eyeson-preview #preview .video-wrap.no-cam {
  background: url("./assets/no_webcam.svg") no-repeat center;
  background-size: 10rem; }

.eyeson-preview #preview .video-wrap.permission-denied {
  background: url("./assets/block_webcam.svg") no-repeat center;
  background-size: 10rem; }

.eyeson-preview #preview .tiles {
  display: flex;
  flex-wrap: wrap; }

.eyeson-preview--mobile #preview .tiles {
  justify-content: space-around; }

.eyeson-preview #preview .tile-group {
  display: flex;
  justify-content: center;
  align-items: center; }

.eyeson-preview #preview .join-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: 1rem; }

.eyeson-preview #preview .eyeson-mic-check .icon {
  height: 1rem;
  margin-bottom: 0; }

#preview-actions,
#preview-results {
  flex: 1; }

#preview-results {
  display: flex;
  flex-flow: column; }

@media (max-width: 350px) {
  .eyeson-preview:not(.eyeson-preview--mobile) #preview .tiles {
    flex-direction: column; } }

@media (min-height: 550px) {
  .eyeson-preview #preview .eyeson-video {
    min-height: 19rem;
    max-height: 40vh; }
  body[data-widescreen] .eyeson-preview #preview .eyeson-video {
    min-height: 15rem; } }

@media (orientation: portrait) and (max-height: 550px) {
  .eyeson-preview #preview .eyeson-video {
    max-height: 40vh; } }

@media (min-height: 651px) {
  .eyeson-preview #preview .tile-group > * {
    margin: 0 0.4rem; } }

@media (min-height: 1000px) {
  .eyeson-preview .center {
    margin: 1rem 0 10rem 0; } }

@media (max-height: 650px) and (min-width: 800px) {
  .eyeson-preview .center {
    width: 53rem;
    max-width: 53rem; }
  .eyeson-preview #preview .preview-wrapper {
    flex-direction: row; }
  .eyeson-preview:not(.eyeson-preview--mobile) #preview-actions {
    display: flex;
    justify-content: center;
    flex-flow: row;
    flex-wrap: wrap;
    margin-left: 1rem; }
  #preview .eyeson-video {
    max-height: none;
    height: auto; } }

@media (max-height: 550px) and (max-width: 800px) and (min-width: 350px) {
  .eyeson-preview #preview .preview-wrapper {
    flex-direction: row; }
  .eyeson-preview .center {
    width: auto;
    max-width: none; }
  .eyeson-preview:not(.eyeson-preview--mobile) #preview .tiles {
    flex-flow: column;
    width: 100%;
    padding-left: 1rem; }
  .preview-group {
    width: 100%; }
  #preview-actions {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: space-between; }
  .eyeson-preview--mobile #preview-actions {
    max-height: 19rem; }
  .eyeson-preview #preview .eyeson-video {
    height: auto;
    max-height: none; } }

@media (max-height: 320px) {
  .eyeson-preview #preview .eyeson-video {
    height: 83%;
    max-height: none; }
  .eyeson-preview #preview #preview-actions {
    height: 90%; } }

@media (orientation: portrait), (orientation: landscape) and (max-height: 680px) {
  .eyeson-preview:not(.eyeson-preview--mobile) #preview-actions {
    display: flex;
    flex-flow: column-reverse nowrap; }
  .eyeson-preview #preview .join-section {
    margin-block-start: 0; } }

.eyeson-preview--mobile #preview {
  height: 100%; }

.eyeson-preview--mobile #preview-results {
  background-color: #f0f0f0; }

.eyeson-preview--mobile #preview-actions .tiles {
  margin: 20px 0; }

.eyeson-preview--mobile #eco-mode-section {
  margin: 20px 0;
  padding: 0 28px;
  text-align: start; }
  .eyeson-preview--mobile #eco-mode-section > .row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5em; }

.eyeson-preview--mobile .join-section {
  margin: 20px 0;
  padding: 0 20px; }
  .eyeson-preview--mobile .join-section button {
    width: 100%; }

body[data-theme="dark"] .eyeson-preview--mobile #preview-results {
  background-color: #121212; }

#eco-mode-section.quickjoin-mobile {
  margin: 20px 0;
  padding: 0 28px;
  text-align: start; }
  #eco-mode-section.quickjoin-mobile > .row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5em; }

.fun-fact,
.empty-fun-fact {
  min-height: 200px; }

.fun-fact {
  display: flex;
  max-width: 600px;
  flex-flow: column;
  align-items: center;
  justify-content: center; }
  .fun-fact .emoji {
    margin-top: 1rem; }

@media screen and (max-height: 560px) {
  .fun-fact,
  .empty-fun-fact {
    display: none; } }

.eyeson-action-button {
  width: 100%;
  padding: 0.25rem 0;
  margin: 0.5rem 0 0 auto;
  position: relative;
  cursor: pointer;
  overflow: inherit;
  background: inherit;
  border: none; }
  .eyeson-action-button:hover {
    transition: background-color 0.3s ease; }
  .eyeson-action-button:focus {
    outline: none; }
  .eyeson-action-button.hidden {
    display: none; }
  .eyeson-action-button.locked {
    opacity: 0.3;
    filter: grayscale(1); }

.eyeson-reaction-picker {
  position: relative;
  transition: all 0.3s ease; }

.eyeson-reaction-picker #reaction {
  display: flex;
  align-items: center;
  justify-content: center; }

.eyeson-reaction-picker #reaction.locked {
  opacity: 0.3;
  filter: grayscale(100%); }

.eyeson-mobile-room .reaction_picker .emoji,
.eyeson-reaction-picker .emoji {
  height: 2rem;
  transition: all 0.3s ease; }

.eyeson-reaction-picker .frame {
  position: absolute;
  left: 100%;
  bottom: 0;
  padding-left: 0.5rem; }

.eyeson-reaction-picker > button.sr {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none; }

.eyeson-mobile-room .reaction_picker .content,
.eyeson-reaction-picker .content {
  right: 0;
  width: 21.2rem;
  display: flex;
  padding: 0.5rem;
  position: relative;
  flex-flow: row;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.1rem;
  justify-content: center; }

.eyeson-mobile-room .reaction_picker .content {
  background: rgba(0, 0, 0, 0.8); }

.eyeson-mobile-room .reaction_picker .content .reaction-button,
.eyeson-reaction-picker .content .reaction-button {
  padding: 1rem 1.4rem;
  border-radius: 0.1rem;
  transition: all 0.3s ease;
  background: none;
  border: none;
  outline: none; }
  .eyeson-mobile-room .reaction_picker .content .reaction-button:hover, .eyeson-mobile-room .reaction_picker .content .reaction-button:focus,
  .eyeson-reaction-picker .content .reaction-button:hover,
  .eyeson-reaction-picker .content .reaction-button:focus {
    cursor: pointer;
    background-color: black; }
    .eyeson-mobile-room .reaction_picker .content .reaction-button:hover .emoji, .eyeson-mobile-room .reaction_picker .content .reaction-button:focus .emoji,
    .eyeson-reaction-picker .content .reaction-button:hover .emoji,
    .eyeson-reaction-picker .content .reaction-button:focus .emoji {
      transform: scale(1.2); }

.eyeson-mobile-room .reaction_picker {
  position: absolute;
  bottom: 56px;
  right: 0;
  padding: 0 .5rem .5rem 0; }

@media screen and (orientation: landscape) {
  .eyeson-mobile-room .reaction_picker {
    right: 80px;
    bottom: 0; } }

@media screen and (max-width: 350px) {
  .eyeson-mobile-room .reaction_picker .content,
  .eyeson-reaction-picker .content {
    width: 16.2rem; } }

.eyeson-expansion-panels {
  display: flex;
  flex-flow: column nowrap;
  width: 22.5rem;
  min-width: 22.5rem;
  height: 100%;
  max-height: 100%;
  max-height: 100vh;
  min-height: 0;
  transition: all 0.27s ease-in-out;
  position: relative;
  /**
   * NOTE: ensure not to remove the min-height on the expansion panel as this
   * may lead to errors in Firefox:
   * https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
   **/ }
  .eyeson-expansion-panels--minified {
    margin-right: -18rem; }
  .eyeson-expansion-panels .eyeson-expansion-panel {
    display: flex;
    flex-flow: column nowrap;
    min-height: 3.5rem;
    box-shadow: 3px 0 5px 0 rgba(50, 50, 50, 0.2); }
    .eyeson-expansion-panels .eyeson-expansion-panel--open {
      flex-grow: 1;
      height: 100%; }
    .eyeson-expansion-panels .eyeson-expansion-panel__header {
      display: flex;
      flex-flow: row nowrap;
      cursor: pointer;
      align-items: center;
      box-sizing: border-box;
      min-height: 3.5rem;
      padding-right: .5rem;
      border-radius: unset; }
      .eyeson-expansion-panels .eyeson-expansion-panel__header:focus {
        outline: none; }
        .eyeson-expansion-panels .eyeson-expansion-panel__header:focus > .eyeson-expansion-panel__icon {
          background: rgba(0, 0, 0, 0.25); }
        body[data-theme="dark"] .eyeson-expansion-panels .eyeson-expansion-panel__header:focus > .eyeson-expansion-panel__icon {
          background: rgba(255, 255, 255, 0.25); }
        .eyeson-expansion-panels .eyeson-expansion-panel__header:focus #connection-info-icon-status {
          border-color: #bfbfbf; }
        body[data-theme="dark"] .eyeson-expansion-panels .eyeson-expansion-panel__header:focus #connection-info-icon-status {
          border-color: #717171; }
    .eyeson-expansion-panels .eyeson-expansion-panel__icon {
      margin: 0 .75rem;
      padding: .5rem;
      border-radius: 50%;
      transition: background-color 0.4s ease; }
    .eyeson-expansion-panels .eyeson-expansion-panel__title {
      font-size: 1rem;
      flex-grow: 1; }
    .eyeson-expansion-panels .eyeson-expansion-panel__content {
      overflow: auto;
      overflow-x: hidden;
      overflow-y: auto; }
  .eyeson-expansion-panels .emoji {
    width: 2rem;
    height: 2rem;
    padding: .125rem;
    vertical-align: middle; }
  .eyeson-expansion-panels .snapshot {
    display: block;
    position: relative;
    overflow: hidden;
    background: #3f3f3f; }
    .eyeson-expansion-panels .snapshot .snapshot-buttons {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
      opacity: 0;
      transform: translateY(-10px);
      transition: transform 100ms ease, opacity 100ms ease; }
    .eyeson-expansion-panels .snapshot:hover .snapshot-buttons {
      opacity: 1;
      transform: translateY(0); }
    .eyeson-expansion-panels .snapshot .snapshot-image {
      width: 100%;
      vertical-align: middle;
      filter: opacity(1);
      transition: filter 100ms ease; }
    .eyeson-expansion-panels .snapshot:hover .snapshot-image {
      filter: opacity(0.5); }
    .eyeson-expansion-panels .snapshot .snapshot-title {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      color: whitesmoke;
      padding: 3px 1em;
      font-size: small;
      background: rgba(97, 97, 97, 0.9);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center; }
    @media screen and (prefers-reduced-motion: reduce) {
      .eyeson-expansion-panels .snapshot .snapshot-buttons {
        transform: translateY(0); } }

#gif-channel .body {
  text-align: left; }

#gif-channel .gifs {
  display: flex;
  flex-wrap: wrap;
  min-height: 25rem;
  max-height: 60vh;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 0.5rem;
  outline: none; }

#gif-channel .gif {
  max-width: 14rem;
  transition: all 0.1s ease;
  position: relative;
  flex-basis: 30%;
  flex-grow: 1;
  margin: 0.5rem 0;
  opacity: 0.5; }
  #gif-channel .gif .fav .icon {
    fill: #003bcc;
    animation: pop 0.5s; }
  #gif-channel .gif:hover, #gif-channel .gif:focus {
    cursor: pointer;
    opacity: 1;
    outline: none; }
    #gif-channel .gif:hover .icon, #gif-channel .gif:focus .icon {
      fill: #f0f0f0; }
    #gif-channel .gif:hover .fav .icon, #gif-channel .gif:focus .fav .icon {
      fill: #003bcc; }
  #gif-channel .gif .icon {
    z-index: 999;
    position: absolute;
    top: 0.5rem;
    right: 0.4rem;
    fill: none; }

@keyframes slide {
  100% {
    left: 0; } }
  #gif-channel .gif .cover {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: -100%;
    font-weight: bolder;
    font-size: large;
    color: white;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 2px #191919;
    animation: slide 0.5s forwards; }

#gif-channel .short video,
#gif-channel .short img {
  opacity: 0.5; }

#gif-channel video,
#gif-channel img {
  width: 100%;
  position: relative;
  animation: blur 0.2s ease-out; }

#gif-channel .icon-wrap {
  display: flex;
  max-height: 100%;
  align-items: center;
  border-radius: 0rem 0.1rem 0.1rem 0rem;
  background-color: #003bcc; }

#gif-channel .icon {
  width: 2rem;
  height: 1.1rem; }

.eyeson-mobile-room #mobile-participants-list {
  position: absolute;
  top: 64px;
  right: 0;
  z-index: 1;
  background: rgba(245, 245, 245, 0.98);
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-bottom-left-radius: 4px;
  box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.2); }
  .eyeson-mobile-room #mobile-participants-list .participants-list--scrollable {
    min-width: 15rem;
    max-width: calc(100vw - 0.5rem);
    min-height: 200px;
    max-height: calc(100vh - 150px - 2rem);
    overflow: auto; }
  .eyeson-mobile-room #mobile-participants-list .participants-list--fixed_bottom {
    text-align: center; }

body[data-theme="dark"] .eyeson-mobile-room #mobile-participants-list {
  background: rgba(66, 66, 66, 0.98);
  border-left: 1px solid #424242;
  border-bottom: 1px solid #424242; }

@media screen and (orientation: landscape) {
  .eyeson-mobile-room #mobile-participants-list {
    top: auto;
    bottom: 0;
    left: 64px;
    right: auto;
    border-left: none;
    border-right: 1px solid #f0f0f0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.2); }
    .eyeson-mobile-room #mobile-participants-list .participants-list--scrollable {
      max-width: calc(100vw - 150px);
      max-height: calc(100vh - 3.5rem); }
  body[data-theme="dark"] .eyeson-mobile-room #mobile-participants-list {
    border-left: none;
    border-right: 1px solid #424242;
    border-bottom: 1px solid #424242; } }

.eyeson-mobile-room #mobile-connection-info {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  max-height: calc(100% - 64px - 56px);
  overflow: auto;
  background: #f5f5f5;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14); }
  .eyeson-mobile-room #mobile-connection-info .overlay-close {
    position: absolute;
    top: 0;
    right: 0; }

body[data-theme="dark"] .eyeson-mobile-room #mobile-connection-info {
  background: #212121; }

@media screen and (orientation: landscape) {
  .eyeson-mobile-room #mobile-connection-info {
    top: auto;
    bottom: 0;
    left: 64px;
    right: 80px;
    max-height: 100%;
    box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2); } }

.eyeson-chat img.emoji {
  height: 1.25em;
  width: 1.25em;
  margin: 0 0.05em 0 0.1em;
  padding: 0;
  vertical-align: top; }

.eyeson-chat .eyeson-chat-feed svg.icon {
  width: 1rem;
  height: 1rem;
  fill: #606060; }

.eyeson-chat .eyeson-chat-feed .icon-wrap {
  display: inline-block;
  vertical-align: middle; }

.eyeson-chat .eyeson-chat-feed .eyeson-avatar {
  width: 2rem;
  height: 2rem; }

.eyeson-chat .eyeson-chat-feed .badge {
  background: #003bcc;
  color: #c6c6c6;
  font-weight: bold;
  font-size: smaller;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  cursor: pointer; }

.eyeson-chat .eyeson-chat-feed .eyeson-date-divider {
  border-top: 1px solid #606060;
  text-align: center;
  margin-right: 1rem;
  margin-top: 1.5rem; }
  .eyeson-chat .eyeson-chat-feed .eyeson-date-divider .date-break {
    background: #ffffff;
    position: relative;
    top: -0.725rem;
    padding: 0.5rem;
    font-size: small; }

.eyeson-chat .eyeson-chat-feed .feed-entry {
  margin: 1rem;
  box-sizing: border-box; }

.eyeson-chat .eyeson-chat-message {
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem; }
  .eyeson-chat .eyeson-chat-message.attribution, .eyeson-chat .eyeson-chat-message.status {
    font-size: 0.8rem;
    display: block;
    padding: 0 1rem;
    text-align: center; }
  body[data-theme="light"] .eyeson-chat .eyeson-chat-message.attribution,
  body[data-theme="light"] .eyeson-chat .eyeson-chat-message.status {
    color: #191919; }
  .eyeson-chat .eyeson-chat-message.chat-message__followup {
    margin-top: -0.6rem; }
  .eyeson-chat .eyeson-chat-message .content {
    color: #606060;
    background-color: white;
    box-sizing: border-box;
    border-radius: 1.25rem;
    margin: 0 0.5rem;
    padding: 0.75rem 1.25rem;
    max-width: 18.5rem;
    overflow-wrap: break-word; }
    .eyeson-chat .eyeson-chat-message .content a {
      word-break: break-all; }
    .eyeson-chat .eyeson-chat-message .content .location-map a[href="#"] {
      text-decoration: none; }
  .eyeson-chat .eyeson-chat-message .chat-message-button__flex-center {
    align-self: center; }
  .eyeson-chat .eyeson-chat-message .eyeson-avatar {
    border: none;
    background: #e0e0e0; }
  .eyeson-chat .eyeson-chat-message .eyeson-avatar__placeholder {
    background: none; }
  .eyeson-chat .eyeson-chat-message.me {
    justify-content: flex-end; }
    .eyeson-chat .eyeson-chat-message.me .content {
      background-color: #f0f0f0; }
    .eyeson-chat .eyeson-chat-message.me .content + img {
      margin-left: -3rem; }
  .eyeson-chat .eyeson-chat-message.question .icon-wrap__question {
    background-color: #003bcc;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding-top: 0.325rem;
    text-align: center;
    flex-shrink: 0; }
  .eyeson-chat .eyeson-chat-message.question .icon-question {
    font-size: 1.25rem;
    color: #ffffff; }
  .eyeson-chat .eyeson-chat-message.question .content {
    color: #003bcc; }
    .eyeson-chat .eyeson-chat-message.question .content a {
      color: #ffffff; }
  .eyeson-chat .eyeson-chat-message.notify .icon-wrap__notify {
    background-color: #003bcc;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding-top: 0.325rem;
    text-align: center;
    flex-shrink: 0; }
  .eyeson-chat .eyeson-chat-message.notify .icon-notify {
    font-size: 1.25rem;
    color: #ffffff; }
  .eyeson-chat .eyeson-chat-message.notify .content {
    color: #003bcc; }
    .eyeson-chat .eyeson-chat-message.notify .content a {
      color: #ffffff; }
  .eyeson-chat .eyeson-chat-message .username {
    margin-right: 0.2rem; }
  .eyeson-chat .eyeson-chat-message img + .content,
  .eyeson-chat .eyeson-chat-message .icon + .content {
    margin-left: 0.4rem; }
  .eyeson-chat .eyeson-chat-message span.details {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.25rem; }
  .eyeson-chat .eyeson-chat-message .text--italic {
    font-style: italic; }
  .eyeson-chat .eyeson-chat-message .text--bold {
    font-weight: bold; }

.eyeson-expansion-panel .participantslist__item {
  overflow-wrap: break-word; }

.eyeson-room {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap; }

.eyeson-container {
  width: 100%; }

.canvas {
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .canvas .canvas-wrapper {
    height: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .canvas .canvas-wrapper.expanded {
    max-height: calc(100% - 8.5em - 3.5rem); }
  .canvas .canvas-wrapper.collapsed {
    max-height: calc(100% - 3.5rem); }
  .canvas .canvas-wrapper.canvas-media {
    max-height: calc(100% - 7rem); }
  .canvas canvas {
    max-height: 100%;
    max-width: 100% !important;
    object-fit: contain; }
  .canvas .canvas-media canvas {
    width: 100%;
    height: 100%; }
  .canvas .cloudy {
    opacity: 0.2;
    filter: blur(2px);
    position: absolute; }

.canvas-wrapper {
  width: 100%; }

.thumbnail-container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1; }

.thumbnails {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: auto;
  overflow-y: unset;
  overflow-x: auto;
  min-height: 8.5em;
  max-height: 10rem; }

.thumbnail {
  margin: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  position: relative;
  max-height: 100%;
  height: 7rem; }
  .thumbnail .page-num {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }
    .thumbnail .page-num:hover {
      color: #191919;
      background: rgba(0, 0, 0, 0); }

.thumbnail.portrait {
  min-width: 100px;
  height: 110px; }

.thumbnail.landscape {
  min-height: 80px;
  min-width: 180px; }

.thumbnail.visible {
  background: white;
  border-color: white; }
  .thumbnail.visible:hover {
    cursor: pointer;
    color: #191919;
    opacity: 1; }

.thumbnail.visible.no-preview {
  animation: fadeIn 0.2s; }

.thumbnail.placeholder {
  border: 2px dashed; }

.thumbnail.preview {
  background-repeat: no-repeat;
  background-size: cover; }

.thumbnail.active {
  border-color: #003bcc; }
  .thumbnail.active .page-num {
    color: #191919;
    background-color: rgba(0, 0, 0, 0); }

.dropzone {
  width: 100%;
  display: flex;
  justify-content: center; }
  .dropzone:focus {
    outline: none; }

.drop-popup {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.95;
  background: rgba(0, 0, 0, 0.7); }
  .drop-popup .frame {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    border: 2px dashed white; }
    .drop-popup .frame svg {
      margin-bottom: 0.5rem; }
    .drop-popup .frame h1 {
      line-height: 2em; }

.podium-outline {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 1rem; }
  .podium-outline .header {
    text-align: center; }

.podium-outline .podium {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 980px;
  margin: auto; }

.podium-outline .podium-frame {
  display: flex;
  padding: 1rem;
  flex-flow: column;
  max-height: 100vh; }

.podium-outline .podium-frame .participants {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.podium-outline .podium-frame .video {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem; }

.podium-outline .podium-frame .audio .participants {
  justify-content: flex-start; }

.podium-outline .participant .eyeson-avatar {
  border: 0; }

.podium-outline .audio .participant .eyeson-avatar {
  width: 30px;
  height: 30px;
  margin: 0.5rem; }

.podium-outline .video .participant .eyeson-avatar {
  width: 74px;
  height: 74px;
  margin-bottom: 1rem; }

.podium-outline .presentation .participant .eyeson-avatar {
  width: 74px;
  height: 74px;
  margin-bottom: 1rem; }

.podium-outline .audio .participant {
  background: inherit;
  display: flex;
  align-items: center; }

.podium-outline .video .participant {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  flex-grow: 1;
  height: 20vh;
  background: rgba(109, 109, 109, 0.5);
  margin: 0.1rem;
  overflow: auto; }

.podium-outline .presentation {
  width: 100%;
  align-self: center; }
  .podium-outline .presentation .participants {
    margin: 8px;
    background: rgba(109, 109, 109, 0.5); }

.podium-outline .presentation .participant {
  padding: 2rem 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column; }

@media screen and (max-width: 620px) {
  .podium-outline .video .participant .eyeson-avatar {
    width: 40px;
    height: 40px; } }

@media screen and (max-height: 850px) {
  .podium-outline .video .participant .eyeson-avatar {
    width: 40px;
    height: 40px; } }

@media screen and (max-height: 700px) {
  .podium-outline h4 {
    margin: 0; }
  .podium-outline .video .participant .eyeson-avatar {
    margin-bottom: 0; }
  .podium-outline .video .participant {
    height: 15vh; } }

@media screen and (max-height: 560px) {
  .podium-outline h3 {
    display: none; }
  .podium-outline .video .participant .eyeson-avatar {
    width: 30px;
    height: 30px;
    margin-bottom: 0; } }

@media screen and (max-height: 450px) {
  .podium-outline h4 {
    display: none; }
  .podium-outline .video .participant {
    flex-flow: row; } }

.eyeson-video {
  width: 100%;
  height: 100%;
  overflow: visible;
  display: block;
  animation: blur 1s ease-out;
  outline: none; }

canvas.eyeson-video {
  margin: 0 auto; }

.eyeson-video.local-stream {
  object-fit: contain; }

.eyeson-video.hidden {
  display: none; }

.eyeson-video.canvasHidden {
  height: 1px !important;
  min-height: 0 !important;
  margin-top: -1px; }

.video-stream-container {
  width: 100%;
  height: 100%;
  overflow: visible;
  display: block;
  position: relative; }

.pip video {
  opacity: 0; }

#pip-overlay {
  display: grid;
  align-content: center;
  justify-items: center;
  position: absolute;
  inset: 1rem;
  z-index: 1; }

#pip-overlay .icon-pip_off {
  width: 1.25rem;
  height: 1.25rem;
  margin-inline-end: 0.8em; }

.audio-stream {
  display: flex;
  align-self: center;
  flex-flow: column; }
  .audio-stream .sound-wave {
    display: flex;
    position: absolute;
    right: 1rem;
    align-items: center; }

.audio-stream .avatar {
  align-self: center; }
  .audio-stream .avatar .eyeson-avatar {
    width: 100px;
    height: 100px;
    border: 0;
    display: flex; }

.audio-stream.main {
  position: relative;
  top: -5rem; }
  .audio-stream.main h2 {
    margin-bottom: 2rem;
    word-break: break-word; }
  .audio-stream.main .avatar {
    position: relative; }
  .audio-stream.main .avatar .default {
    opacity: 0.5;
    filter: blur(2px); }
  .audio-stream.main .sound-wave {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 100px;
    bottom: 0;
    right: 0;
    padding: 5px; }

.playback {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

@media screen and (orientation: landscape) {
  .audio-stream.main {
    top: -3rem; } }

@media screen and (max-height: 350px) {
  .audio-stream.main {
    top: -1rem; } }

.name-insert {
  color: #ffffff;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none; }

.name-insert .name {
  padding-left: 2rem;
  display: flex;
  align-items: center; }

.voice-activity-indicator {
  display: flex; }

.name-insert .MuiIcon-root,
.name-insert .icon {
  margin-right: 1rem;
  color: #ffffff;
  fill: #ffffff; }

.name-insert .icon {
  width: 2rem;
  height: 1.7em; }

.activity-indicator-group {
  display: flex;
  align-items: center; }

.name-insert.float {
  top: auto;
  bottom: 12.5rem;
  border-radius: 0.1rem;
  width: 20em;
  width: max-content;
  min-width: 20em;
  max-width: 50vw;
  margin: auto;
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }
  .name-insert.float .voice-activity-indicator {
    flex-shrink: 0; }
    .name-insert.float .voice-activity-indicator .material-icons {
      font-size: 1.2rem; }
  .name-insert.float .name {
    display: block;
    padding: 0;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem; }

.name-insert.float.collapsed {
  bottom: 5rem; }

.eyeson-mobile-room .name-insert.float {
  align-self: center; }

.eyeson-draggable {
  position: absolute;
  display: inline-block;
  cursor: grab; }
  .eyeson-draggable .eyeson-video {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.1rem;
    background: url("./assets/no_video.svg") no-repeat center;
    background-color: #121212;
    background-size: 40%; }

.self-view {
  right: 1rem;
  bottom: 1rem; }

.self-view-video {
  width: 15vw;
  height: calc(11.25vw); }

body[data-widescreen] .self-view-video {
  height: calc(8.4375vw); }

#location-map-wrap {
  position: relative; }

#location-map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 400;
  pointer-events: none; }

#location-map {
  height: 60vh; }

@media screen and (max-height: 900px) {
  #location-map {
    height: 50vh; } }

#location-map:not(.leaflet-container) ~ #location-map-marker {
  display: none; }

#location-map-marker img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-12.5px, -100%, 0); }

#location-map a[href="#"] {
  text-decoration: none; }

#location-map-reset {
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 400;
  color: #000;
  background-color: #ffffffee;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

#location-map-reset[disabled] {
  color: #aaa;
  pointer-events: auto; }

#location-map-reset:hover,
#location-map-reset:focus-visible {
  background-color: #f4f4f4; }

svg.icon {
  width: 3rem;
  height: 2rem; }

svg .hover {
  visibility: hidden; }

svg:hover .hover {
  visibility: visible; }

.MuiListItemIcon-root svg.icon {
  width: 1.5rem;
  height: 1.5rem; }

.eyeson-action-button .MuiIcon-root {
  font-size: 2rem; }

.eyeson-draggable {
  z-index: 100; }

.eyeson-action-button {
  z-index: 101; }

.name-insert {
  z-index: 90; }

.eyeson-reaction-picker {
  z-index: 100; }

.drop-popup {
  z-index: 1000; }

.toolbar {
  z-index: 998; }

#youtube-popup {
  display: grid;
  height: 100%;
  padding: 16px;
  text-align: center;
  align-content: center; }
  #youtube-popup .youtube-popup__form {
    margin-top: 12px;
    text-align: center; }
    #youtube-popup .youtube-popup__form .row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 8px 0; }
    #youtube-popup .youtube-popup__form .buttons {
      margin-top: 24px; }
      #youtube-popup .youtube-popup__form .buttons button {
        margin: 0 8px; }
  #youtube-popup .spinner-wrap {
    margin: 16px 0; }
  #youtube-popup .youtube-popup__description {
    text-wrap: balance; }
